<template>
<section id="werehouse-outputs">
    <section id="advance-search">
        <div class="card mb-5 mb-xl-10">
                <div
                    class="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#${idCollapse}`"
                    aria-expanded="true"
                    :aria-controls="idCollapse"
                >
                    <div class="card-title m-0">
                        <span class="svg-icon svg-icon-2 svg-icon-gray-500 me-1">
                            <inline-svg src="media/icons/duotune/general/gen021.svg" />
                        </span>
                        <h3 class="fw-bolder ml-2">{{ $t('LABELS.ADVANCE_SERACH') }}</h3>
                    </div>
                </div>
                <div class="collapse hide m-10" :id="idCollapse">
                    <Form as="el-form" @submit="searchOutputs" :validation-schema="validationSchemaFilters"  ref="formFilters">
                        <div class="row g-9 mb-8">
                            <div class="col-md-3 col-xs-12  fv-row">
                                <label>{{ $t('LABELS.FOLIO') }}</label>
                                <InputText :name="'folio'">

                                </InputText>
                            </div>
                             <div class="col-md-3 col-xs-12  fv-row">
                                <label>{{ $t('LABELS.FOLIO_OC') }}</label>
                                <InputText :name="'ocfolio'">

                                </InputText>
                            </div>
                            <div class="col-md-3 col-xs-12 fv-row">
                                <label>{{ $t('LABELS.FOLIO_CUSTOMER') }}</label>
                                <InputText :name="'customerFolio'">

                                </InputText>
                            </div>
                            <div class="col-md-3 col-xs-12 fv-row">
                                <label>{{ $t('LABELS.DATE_DELIVERY') }}</label>
                                <DatePickerRange></DatePickerRange>
                            </div>
                            <div class="col-md-4 col-xs-12 fv-row">
                                <label>{{ $t('LABELS.NAME_CUSTOMER') }}</label>
                                <SelectFilterRemote :name="'customerIds'" :options="customerOptions" 
                                    :multiple="true"
                                    @search="searchCustomers"
                                    :placeholder="'Filtro por nombre'">
                                </SelectFilterRemote>
                            </div>
                            <div class="col-md-4 col-xs-12  fv-row">
                                <label>{{ $t('LABELS.TYPE_OUTPUT') }}</label>
                                <Select :name="'filterType'" :options="optionsTypes">

                                </Select>
                            </div>
                            <div class="col-md-4 col-xs-12  fv-row">
                                <label>{{ $t('LABELS.STATUS') }}</label>
                                <Select :name="'status'" :options="optionsStatus">

                                </Select>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                                <button 
                                type="reset"
                                id="kt_modal_new_target_cancel"
                                class="btn btn-sm btn-white me-3 float-start"
                                data-kt-menu-dismiss="true"
                                @click="cleanFilters"
                            >
                                {{ $t("BUTTONS.CLEAN_FILTERS_FORM") }}
                            </button>
                            <button type="submit" class="btn btn-success" ref="btnFilters">
                                        <span class="svg-icon svg-icon-2 svg-icon-gray-500 me-1">
                                            <inline-svg src="media/icons/duotune/general/gen021.svg" />
                                        </span>
                                        {{ $t('BUTTONS.SEARCH_INPUTS') }}
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
    </section>
<section id="table-outputs">
<ModuleMaster 
    :requireSearchField="false"
    :requireAddButton="canCreate"
    :requirePaginator="true"
    :useFilters="false"
    :labels="labels"
    :numItemsPaginator="totalRecords"
    :selectedPage="selectedPage"
    :itemsPerPage="getItemsPerPage"
    :fnCallbackChangePage="handleChangePage"
    :fnCallbackCrudAction="handleCrudAction"
    :fnCallbackSearchItems="searchItems"
>
    <template v-slot:filters>
        <MovementFilters :fnCallbackFilters="applyFilters" 
            requireOC
            requireLiquidation
            requireShipAdmin
        ></MovementFilters>
    </template>
    <Datatable :headers="headers" :requireActions="true" :requireStatusCol="false">
        <Datarow 
            v-for="row in renderRows" 
            v-bind:key="row.ID" 
            :dataRow="row" 
            :showCrudActions="true"
            :requireStatusCol="false"
            :statusPropName="'status'"
        >
            <CrudActions :itemID="row.ID" :callbackCrudAction="handleCrudAction" :requireM="canEdit(row.ID)" :requireE="false"/>
            <AditionalActions 
                :fnCallback="handleAditionalActionClick"
                :rowId="row.ID"
                :requireDetails="true"
                :requireDelivery="getRequireDelivery(row.ID)"
                :requireCommited="getRequireCommited(row.ID)"
            /> 
             <div class="btn btn-icon btn-sm btn-active-icon-primary" 
                    @click="print(row.ID)"
                data-bs-toggle="tooltip" data-bs-placement="bottom" :title="$t(`TOOLTIPS.PRINT`)">
                    <i class="bi bi-printer-fill fs-4"></i>
            </div>
        </Datarow>
    </Datatable>
</ModuleMaster>
</section>
</section>
<button hidden data-bs-toggle="modal" data-bs-target="#modalInputEditForm" ref="btnForm"></button>
<OutputEditForm ref="form" :mode="modeEditForm" :title="titleEditFormAction" :currentOpenedItem="currentArticle" :fnCallBackPersistenceNotifier="handleItemSaved"/>
<button hidden ref="openPDF" data-bs-toggle="modal" data-bs-target="#viewPDF" />
<PDFViewer :base64="pdfMovement" titulo="Movimiento de Salida" ></PDFViewer>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import ModuleMaster from "../../../components/c-module-master/module-master.vue";
import CrudActions from "../../../components/c-module-master/crud-actions.vue";
import Datatable from "../../../components/c-datatable/Datatable.vue";
import Datarow from "../../../components/c-datatable/Datarow.vue";
import ApiService from "@/core/services/ApiService";
import { formatToDatatable } from "../../../common/formaterToDatatable";
import OutputEditForm from "./OutputEditForm.vue";
import SwalMessageService from "@/core/services/SwalMessageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import { useRouter } from "vue-router";
import AditionalActions from "../common/aditional-actions.vue";
import { useStore } from "vuex";
import  PDFViewer from "@/views/cashregister/components/PDFViewer.vue";
import MovementFilters from "../common/MovementsFilters.vue";
import { filter } from "../common/commonUtilities";
import { toggleCollapse } from '@/core/helpers/dom';
import * as Validations from "yup";
import InputText from '@/components/forms/InputText.vue'
import Select from '@/components/forms/Select.vue'
import SelectFilterRemote from '@/components/forms/SelectFilterRemote.vue'
import DatePickerRange from '@/components/forms/DatePickerRange.vue'
import { Form } from 'vee-validate'
import _ from "lodash";

export default defineComponent({
    components: {
        ModuleMaster,
        Datatable,
        Datarow,
        CrudActions,
        OutputEditForm,
        AditionalActions,
        PDFViewer,
        MovementFilters,
        Form,
        InputText,
        DatePickerRange,
        Select,
        SelectFilterRemote
    },
    setup() {
        //VARIABLES Y CONSTANTES
        const messageService = new SwalMessageService();
        const router = useRouter();
        let records = ref([]);
        let modeForm = ref("N");
        let currentPage = ref(1);
        let cantRecordsPage = ref(20);
        let totalItems = ref(0);
        const btnForm = ref(null);
        const btnDetails = ref(null);
        const currentItem = ref(null);
        let textSearch = ref("");
        const openPDF = ref(null);
        let pdfMovement = ref("");
        let form = ref(null);
         let customerOptions = ref([]);
        let optionsTypes = ref([]);
        const controller = "outputs";
        let titleEditForm = ref("Nuevo Movimiento de Salida");
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar",
            titleNewCrudAction: "Nuevo Registro",
        });
        const arrayTitleActions = [
            { action: "N", title: "Nuevo Movimiento de Salida" },
            { action: "M", title: "Modificar Movimiento de Salida" },
            { action: "V", title: "Visualizar Movimiento de Salida" }
        ];
        
        const configTable = reactive([
            { PropName: "folio", HeadLabel: "Folio de Salida", Type: "text", VisibleInGrid: true },
            { PropName: "relatedFolio", HeadLabel: "Folio de Venta", Type: "text", VisibleInGrid: true },
            { PropName: "movementTypeName", HeadLabel: "Tipo de Salida", Type: "text", VisibleInGrid: true },
            { PropName: "customerName", HeadLabel: "Cliente", Type: "text", VisibleInGrid: true },
            { PropName: "createdAt", HeadLabel: "Fecha Movimiento", Type: "date", VisibleInGrid: true },
            { PropName: "total", HeadLabel: "Total", Type: "moneda", VisibleInGrid: true }
        ]);

        const store = useStore();

        const formFilters = ref();
        const btnFilters = ref();
        const validationSchemaFilters = Validations.object().shape({
            folio: Validations.string().default('')
            , ocFolio: Validations.string().default('')
            , customerFolio: Validations.string().default('')
            , customerIds: Validations.array().default([])
            , startDate: Validations.date().default(undefined).optional()
            , endDate: Validations.date().default(undefined).optional()
            , filterType: Validations.mixed()//.oneOf(getOptions().map(s => s.id))//solo acepta enumerador de recepciones
            , status: Validations.string().default('').optional()
        })

        const idCollapse = ref('collapse-outputs-filters');

        let intervalId;

        const optionsStatus = [
            { id: 0, name: "Cptura Inicial"},
            { id: 1, name: "Finalizadas"},
            { id: 2, name: "Aprobadas"},
            { id: 3, name: "Canceladas"},
            { id: 5, name: "Precomprometidas"},
            { id: 4, name: "Comprometidas"}            
        ]

        //HOOKS
        onBeforeMount(async() => {
            await getPagedItems();
            intervalId = setInterval(() => executeGetPagedItemsSec(), 120000)
        });

        onMounted(() => {
            //resetFilterDateInitial("onMounted");
            setCurrentPageBreadcrumbs("Salidas", ["Módulos", "Almacén"]);
            getComboOptions("MovementTypes", optionsTypes, "combo/outputs", "", "");
        });

        onBeforeUnmount(() => {
            clearInterval(intervalId);
        });

        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(records.value, configTable, "status");
        });

        const currentArticle = computed(() => {
            return currentItem.value;
        });

        const titleEditFormAction = computed(() => {
            return titleEditForm.value;
        });

        const totalRecords = computed(() => {
            return totalItems.value;
        });

        const selectedPage = computed(() => {
            return currentPage.value;
        });

        const modeEditForm = computed(() => {
            return modeForm.value;
        });

        const getItemsPerPage = computed(() => {
            return cantRecordsPage.value;
        });


        /**PERMISOS */
        const canCreate = computed(() =>  store.getters["canCreateInputs"]);
        const canApprovalShipManager = computed(() => store.getters["canManagerShipApproval"]);
        const canApprovalWarehouseManager = computed(() => store.getters["canManagerWarehouseApproval"]);
        
        //FUNCIONES

        const resetFilterDateInitial = (origin = "template") => {
            console.log(origin)
            const endDate = new Date();
            const startDate = new Date(endDate);
            startDate.setDate(startDate.getDate() - 15);
            formFilters.value.setFieldValue('startDate', startDate.toISOString())
            formFilters.value.setFieldValue('endDate', endDate.toISOString())

            let filterStartDate = `&Criteria.InitialDate=${startDate.toISOString()}`;
            let filterEndDate = `&Criteria.FinalDate=${endDate.toISOString()}`;
            let parameters = `Criteria.Active=true${filterStartDate}${filterEndDate}`
            getPagedItems(parameters)
            clearInterval(intervalId);
            intervalId = setInterval(() => executeGetPagedItemsSec(`Criteria.Active=true${filterStartDate}${filterEndDate}`), 120000)
        }
        const handleChangePage = async(page) => {
            await goToPage(page);
        }

        const handleCrudAction = async(action, itemID) => {
            modeForm.value = action;
            if(["N", "V", "M"].some(a => a === action)) {
                titleEditForm.value = arrayTitleActions.find(ac => ac.action == action).title;
                if(action === "M" || action === "V") {
                    await getArticleById(itemID);
                } 
                
                if(btnForm.value) {
                    form.value.initDefaults();
                    btnForm.value.click();
                }
            } else if(action === "E") {
                let item = records.value.find(i => i.id === itemID);
                if(item) {
                    Swal.fire({
                        title: `Está a punto de ${ item.status ? "desactivar" : "activar"} el registro. ¿Desea continuar?`,
                        icon: "warning",
                        showDenyButton: false,
                        showCancelButton: true,
                        confirmButtonText: 'Sí',
                        cancelButtonText: 'No',
                        denyButtonText: 'No',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                changeStatusItem(item);
                            }
                        }
                    );
                    
                }  
            }
        }

        const changeStatusItem = async(item) => {
            let activate = !item.status;
            let url = `products/${item.id}/${activate ? 'activate' : 'deactivate'}`;
            await ApiService.put(url).then(res => {
                item.status = activate;
                handleItemSaved(item);
                messageService.success(`El registro se ${activate ? 'activó' : 'desactivó'} exitosamente.`);          
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const searchItems = async(text) => {
            currentPage.value = 1;
            textSearch.value = text;
            await getPagedItems();
            clearInterval(intervalId);
            intervalId = setInterval(() => executeGetPagedItemsSec(), 120000)
        }

        const getPagedItems = async(criteria = "Criteria.Active=true") => {
            let url = `${controller}/GetPagedList?currentPage=${currentPage.value}&PageSize=${cantRecordsPage.value}&${criteria}`;
            try {
                const res = await ApiService.query(url);
                totalItems.value = res.data.totalRecords;
                records.value = res.data.data;
            } catch (reject) {
                getMessageError(reject, messageService);
            }
        }

        const executeGetPagedItemsSec = async(criteria) => {
            await getPagedItems(criteria);
        }

        const getArticleById = async(id) => {
            ApiService.get(`${controller}/${id}`).then(res => {
                currentItem.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const handleItemSaved = (item) => {
            switch(modeForm.value) {
                case "N":
                    if(currentPage.value > 1) {
                        goToPage(1);
                    } else {
                        ApiService.get(`${controller}/${item.id}`).then(res => {
                            if(records.value.length == cantRecordsPage.value){
                                records.value = [res.data, ...records.value.slice(0, cantRecordsPage.value - 1)]
                            } else {
                                records.value = [res.data, ...records.value];
                            }
                            totalItems.value += 1;     
                        }).catch(reject => {
                            getMessageError(reject, messageService);
                        });                  
                    }
                    break;
                case "M":
                    refreshSelectedItem(item.Id, updateRowItem);
                    break;
                case "E":
                     updateStatusRow(item.Id, item.status);
                    break;
            }
        }

        const goToPage = async (page) => {
            currentPage.value = page;
            await getPagedItems();
        }

        const updateRowItem = (item) => {
            let itemRow = records.value.find(ir => ir.id === item.id);
            if(itemRow) {
                itemRow.warehouseName = item.warehouseName;
                itemRow.userName = item.userName;
                itemRow.invoiceFolio = item.invoiceFolio;
                itemRow.note = item.note;
                itemRow.liquidationFolio = item.liquidationFolio;
                itemRow.movementStatus = item.movementStatus;
            }
        }

        const updateStatusRow = (idRow, status) => {
            let itemRow = records.value.find(ir => ir.id === idRow);
            if(itemRow) {
                itemRow.status = status;
            }
        }

        const handleAditionalActionClick = (action, movementeId) => {
            switch(action) {
                case "DET":
                    goToDetails(movementeId);
                    break;
                case "ASM":
                    approvalMovement(movementeId, "approveByTheShipManager");
                    break;
                case "AWM":
                    approvalMovement(movementeId, "approveByTheWarehouseManager");
                    break;
                case "CI":
                    approvalMovement(movementeId, "Initial", "ChangeStatusMovements", "Está a punto de reabrir la captura del movimiento de entrada.");
                    break;
                case "DVY":
                    approvalMovement(movementeId, "EndCapture", "Outputs", "Está a punto de finalizar el Movimiento de Salida.");
                    break;
                case "CM":
                    approvalMovement(movementeId, "Committed", "Outputs", "Está a punto de finalizar el Movimiento de Salida.");
                    break;
                case "DM":
                    getPurchaseOrderPDF(movementeId);
                    break;
            }
        }

        const goToDetails = (movementeId) => {
            ApiService.get(`${controller}/${movementeId}`).then(res => {
                let dataProps = `outputId:${movementeId}|canEdit:${res.data.status == 0}|movementFolio:${res.data.folio}|requireDvy:${canDeliver(movementeId)}|requireCmt:${canCommit(movementeId)}|requireAWM:${canApprovalWarehouseManager.value && showApprovalWarehouseManagerOption(movementeId)}`;
                if(res && res.status == 200){
                    router.push({
                        name: '/warehouse/output/:outputId/details', 
                        params: {
                            outputId: btoa(dataProps),
                            canEdit: canEdit(movementeId),
                            movementFolio: res.data.folio
                        }
                    });
                }    
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const approvalMovement = (movementeId, approvalType, controllerAp = "inputs", messageTitle = "Está a punto de autorizar el movimiento.") => {
            Swal.fire({
                title: `${messageTitle} ¿Desea continuar?`,
                icon: "warning",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Sí',
                cancelButtonText: 'No',
                denyButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {
                        ApiService.put(`${controllerAp}/${movementeId}/${approvalType}`).then(res => {
                            if(res.status == 204){
                                refreshSelectedItem(movementeId, updateApprobalStatusCol, approvalType);                          
                            }     
                        }).catch(reject => {
                            getMessageError(reject, messageService);
                        }); 
                    }
                }
            );
        }

        const refreshSelectedItem = (itemId, callbackFunction, approvalType = "") => {
            ApiService.get(`${controller}/${itemId}`).then(res => {
                if(res && res.status == 200 && callbackFunction){
                    callbackFunction(res.data, approvalType);
                }    
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const approvalMessages = [
            { type: "EndCapture", message: "El movimiento de salida ha finalizado de menera correcta." },
            { type: "Committed", message: "El movimiento de salida ha finalizado de menera correcta." },
            { type: "Initial", message: "La captura del movimiento de salida ha sido reabierta de menera correcta." },
            { type: "default", message: "El movimiento de salida fue autorizado de manera correcta." }
        ];

        const getApprovalMessage = (approvalType) => {
            let approvalMsg = approvalMessages.find(am => am.type == approvalType);
            if(approvalMsg == null) {
               approvalMsg = approvalMessages.find(am => am.type == "default");
            }
            return approvalMsg ? approvalMsg.message : "El cambio de etapa se realizó de manera correcta.";
        }

        const updateApprobalStatusCol = (item, approvalType) => {
            let itemRow = records.value.find(ir => ir.id === item.id);
            let message = getApprovalMessage(approvalType);
            if(itemRow) {
                if(approvalType == "EndCapture") {
                    itemRow.status = 1;
                }

                if(approvalType == "Committed") {
                    itemRow.status = 5;
                }
                messageService.success(message);
            }
        }

        const showApprovalWarehouseManagerOption = (movementId) => {
            let movement = getCurrentRenderMovement(movementId);
            return movement ? movement.eMovementStatus == 1 && !movement.whereHouseManagerApproval: false;
        }

        const canDeliver = (movementId) => {
            let movement = getCurrentRenderMovement(movementId);
            let temp = movement ? movement.status == 0 : false;
            return temp;
        }

        const canCommit = (movementId) => {
            let movement = getCurrentRenderMovement(movementId);
            return movement ? movement.status == 4 : false;
        }

        const canEdit = (movementId) => {
            let movement = getCurrentRenderMovement(movementId);
            return movement ? movement.status == 0 : false; // || store.getters["canEditCaptureEndedMovement"] : false;
        }

        const getCurrentRenderMovement = (movementId) => {
            return records.value.find(r => r.id == movementId);
        }

        const getPurchaseOrderPDF = (orderId) => {
            ApiService.post(`movements/ReportMovementPdf?MovementId=${orderId}` ,{})
            .then( resp => {
                if(resp.status == 200 && openPDF.value) {
                    pdfMovement.value = `data:application/pdf;base64,${resp.data}`;
                    openPDF.value.click();
                }
            }).catch(error => {
                getMessageError(error, messageService);
            });
        }

        const canDownloadPDFMovement = (movementId) => {
            let movement = getCurrentRenderMovement(movementId);
            return movement ? movement.whereHouseManagerApproval : false;
        }

        const applyFilters = async (filters) => {
            let criteria = filter.criteria(filters);
            if(filters.NumberItems) {
                cantRecordsPage.value = filters.NumberItems;
            }
            await getPagedItems(criteria);
        }

        const getRequireDelivery = (rowId) => {
            let output = records.value.find(o => o.id == rowId);
            if(output) {
                return output.status == 0;
            } else return false;
        }

        const getRequireCommited = (rowId) => {
            let output = records.value.find(o => o.id == rowId);
            if(output) {
                return output.status == 4;
            } else return false;
        }
        const searchOutputs = async(filters) => {
            //tableModule.UPDATE_FILTERS(filters)
            let filterFolio =  filters.folio && filters.folio != "" ? `&Criteria.Folio=${filters.folio}` : "";
            let filterOcFolio = filters.ocfolio && filters.ocfolio != "" ? `&Criteria.RelatedFolio=${filters.ocfolio}` : "";
            let filterCustomerFolio = filters.customerFolio && filters.customerFolio != "" ? `&Criteria.CustomerFolio=${filters.customerFolio}` : "";
            let filterCustomerIds = '';
                (filters && filters.customerIds && filters.customerIds.length > 0) ? filters.customerIds.map(customerId => { filterCustomerIds += `&Criteria.CustomerIds=${customerId}` }) : "";
            let filterMovementType = filters.filterType && filters.filterType != "" ? `&Criteria.MovementTypeId=${filters.filterType}` : "";
            let filterStartDate = filters.startDate && filters.startDate != "" ? `&Criteria.InitialDate=${filters.startDate}` : "";
            let filterEndDate = filters.endDate && filters.endDate != "" ? `&Criteria.FinalDate=${filters.endDate}` : "";
            let filterStatus = filters.status && filters.status != "" ? `&Criteria.status=${filters.status}` : "";
            let parameters = `Criteria.Active=true${filterFolio}${filterOcFolio}${filterCustomerFolio}${filterCustomerIds}${filterMovementType}${filterStartDate}${filterEndDate}${filterStatus}`;
            await getPagedItems(parameters);
            toggleCollapse("#" + idCollapse.value);
            clearInterval(intervalId);
            intervalId = setInterval(() => executeGetPagedItemsSec(parameters), 120000)
        }

        const cleanFilters = () => {
            formFilters.value.resetForm();
            resetFilterDateInitial("cleanFilters");
            //btnFilters.value?.click();
        }
       
        const searchCustomers = _.debounce((filterText) => {
            //tableModule.getComboCustomer(filterText);
            getComboOptions("Customer", customerOptions, "combo", "", filterText);
        }, 550, { leading: false, trailing: true });

        const getComboOptions = async (resource, container, method = "combo", parentId = "", criteria = "", filterName = "criteria") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let paramCriteria = criteria.trim() != "" ? `?${filterName}=${criteria}` : "";
            let url = `${resource}/${parentParam}${method}${paramCriteria}`;
            ApiService.query(url).then(res => {
                if (res && res.status == 200) {
                    container.value = res.data;
                }
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const print = (itemId) => { 
            ApiService.get(`${controller}/GetReceipt/${itemId}`)
            .then( resp => {
                if(resp.status == 200 && openPDF.value) {
                    pdfMovement.value = `data:application/pdf;base64,${resp.data}`;
                    openPDF.value.click();
                }
            }).catch(error => {
                getMessageError(error, messageService);
            });
        }

        return {
            //variables y constantes
            labels,
            btnForm,
            form,
            btnDetails,
            openPDF,
            pdfMovement,
            idCollapse,
            formFilters,
            btnFilters,
            validationSchemaFilters,
            optionsStatus,
            //varaibles computadas
            headers,
            renderRows,
            titleEditFormAction,
            totalRecords,
            selectedPage,
            currentArticle,
            modeEditForm,
            canApprovalShipManager,
            canApprovalWarehouseManager,
            canCreate,
            getItemsPerPage,

            //funciones
            handleChangePage,
            handleCrudAction,
            handleAditionalActionClick,
            handleItemSaved,
            searchItems,
            showApprovalWarehouseManagerOption,
            canEdit,
            canDownloadPDFMovement,
            applyFilters,
            getRequireDelivery,
            getRequireCommited
            , print
            , searchOutputs
            , cleanFilters
            , searchCustomers
            , customerOptions
            , optionsTypes
        }
        
    },
})
</script>

<style lang="scss">

    // .itemControl {
    //     width: 180px;
    // }

    .demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}
.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}
.demo-date-picker .block:last-child {
  border-right: none;
}
.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}
</style>

<i18n>
    {
        "es": {
            "LABELS": {
                "ADVANCE_SERACH":"Busqueda Avanzada"
                , "FOLIO":"Folio de Salida"
                , "FOLIO_OC":"Folio de Venta"
                , "NAME_CUSTOMER":"Clientes"
                , "DATE_DELIVERY":"Fecha de Salida"
                , "FOLIO_CUSTOMER":"Folio Cliente"
                , "TYPE_OUTPUT":"Tipo de Salida"
                , "STATUS": "Estatus"
            },
            "BUTTONS":{
                "CLEAN_FILTERS_FORM":"Limpiar"
                , "SEARCH_INPUTS":"Busqueda de Entradas"
            }
        }
    }
</i18n>
